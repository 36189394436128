import { isFunction } from "lodash-es";
const modules = {
    apiwhitelist: true,
    accountactivity: true,
    apikeys: true,
    auditlogs: true,
    cancellations: true,
    categorization: true,
    channelsetup: true,
    customfieldsgroups: true,
    channelactivation: true,
    documentsoverview: true,
    feedmanager: true,
    invoices: true,
    mappings: true,
    marketplacekpi: true,
    notifications: true,
    orderdetail: true,
    orderroutings: true,
    orders: true,
    pricerules: true,
    priceruleexample: true,
    productbundles: true,
    productdetails: process.env.ENABLE_PRODUCTDETAILS === "true",
    productextradata: true,
    productfeeds: true,
    products: true,
    productselection: true,
    promotions: true,
    reports: true,
    returns: true,
    refunds: true,
    roles: true,
    settings: true,
    settlements: true,
    shipmentmethods: true,
    shipments: true,
    statistics: true,
    stockallocations: true,
    stocklocations: true,
    tasks: true,
    users: true,
    usersettings: true,
    vatrates: true,
    webhooks: true,
    merchantsetup: true,
    producttranslations: true,
};
const getModuleRoutes = async (moduleName, rootNode) => {
    try {
        const { routes } = (await import(`../../components/${moduleName}/index`));
        if (isFunction(routes)) {
            const props = JSON.parse(rootNode?.dataset.props ?? "{}");
            return routes(props);
        }
        return routes;
    }
    catch {
        return [];
    }
};
const routeTasks = Object.entries(modules).map(([moduleName, enabled]) => {
    const node = document.querySelector(`#react-app-${moduleName}`);
    // The .net ReactInline component will output a div with id react-app-<module>.
    // If that's not present, don't show react module - useful for beta-view switch button.
    // TODO: Remove `&& node` condition when all routes need to be available for cross-linking via <Link to />
    return enabled && node ? getModuleRoutes(moduleName, node) : Promise.resolve([]);
});
const allRoutes = await Promise.all(routeTasks);
export default allRoutes.flat();
